import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='mt-12 bg-primary  text-white flex justify-center items-center space-x-5'>
       {/* <hr className=' border-1 border-hint' /> */}
       <p className='text-center py-7'>
        Deconsole 2024, All Right Reserved
       </p>
       <p className=' text-gray-900'>
        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/termsofuse">Terms of Use</Link>
      </p>
    </div>
  )
}

export default Footer