import React from 'react'
import { features } from '../data'
import { motion } from 'framer-motion';
import { slideIn, } from '../motion';

const Features = () => {
  return (
    <div className='sides mt-12' id='features'>
        <h5 className='mb-3 font-semibold text-primary'>FEATURES</h5>
        <h2 className='text-4xl font-semibold'>What I Do</h2>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 mt-8'>
            {features.map((item, index)=> 
            <motion.div
            initial={slideIn('up', null).initial}
            whileInView={slideIn('up', index*2).animate}

            className=' elevate flex flex-col space-y-4 px-5 py-8' key={index}>
                <img src={item.icon} alt="" className='w-[36px] h-[36px]' />
                <h3 className= 'text-lg font-semibold'>{item.title}</h3>
                <p className='hint'>{item.desc}</p>
            </motion.div>
            )}
        </div>
    </div>
  )
}

export default Features