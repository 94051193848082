import {
    AiOutlineGithub,
    AiOutlineInstagram,
    AiOutlineLinkedin,
    AiOutlineDownload,
    AiFillTool
} from 'react-icons/ai'

/*------------------------------------------
-----------SERVICES RENDERED
------------------------------------------*/

import mobile from "./assets/icons/mobile.svg";
import frontend from "./assets/icons/frontend.svg";
import backend from "./assets/icons/backend.svg";
import web3 from "./assets/icons/web3.svg";
import cloud from "./assets/icons/cloud.svg";
import seo from "./assets/icons/seo.svg";

/*------------------------------------------
-----------PORTFOLIO BANNER
------------------------------------------*/

import hellotio from "./assets/banner/hellotio.png";
import ccl from "./assets/banner/cloudclinic.png";
import cclpartners from "./assets/banner/partners.png";
import fregies from "./assets/banner/fregies.png";
import promeal from "./assets/banner/promealapp.png";
import smartreferral from "./assets/banner/smartreferral.png";
import smartadmin from "./assets/banner/smartadmin.png";
// import bnportal from "./assets/banner/bnportal.jpg";
import bnportalng from "./assets/banner/bnportalng.jpg";
import promealweb from "./assets/banner/promealweb.png";
import smartbet from "./assets/banner/smartbet.png";
import contact from "./assets/banner/contact.png";

/*------------------------------------------
-----------SKILLS
------------------------------------------*/

import flutter from "./assets/icons/skills/flutter.svg";
import react from "./assets/icons/skills/react.svg";
import tailwind from "./assets/icons/skills/tailwind.svg";
import html from "./assets/icons/skills/html.svg";
import css from "./assets/icons/skills/css.svg";
import bootstrap from "./assets/icons/skills/bootstrap.svg";

import node from "./assets/icons/skills/node.svg";
import express from "./assets/icons/skills/express.svg";
import laravel from "./assets/icons/skills/laravel.svg";
import apache from "./assets/icons/skills/apache.svg";
import nginix from "./assets/icons/skills/nginix.svg";
import rabbitMQ from "./assets/icons/skills/rabitmq.svg";
import socket from "./assets/icons/skills/socket.svg";
import graphql from "./assets/icons/skills/graphql.svg";
import api from "./assets/icons/skills/api.svg";
import firebase from "./assets/icons/skills/firebase.svg";
import solidity from "./assets/icons/skills/solidity.svg";

import php from "./assets/icons/skills/php.svg";
import javascript from "./assets/icons/skills/javascript.svg";
import python from "./assets/icons/skills/python.svg";

import postgress from "./assets/icons/skills/postgress.svg";
import mysql from "./assets/icons/skills/mysql.svg";
import mongodb from "./assets/icons/skills/mongodb.svg";
import firebasestorage from "./assets/icons/skills/firebase.svg";
import redis from "./assets/icons/skills/redis.svg";

import vscode from "./assets/icons/skills/vscode.svg";
import androidstudio from "./assets/icons/skills/androidstudio.svg";
import xcode from "./assets/icons/skills/xcode.svg";
import pycharm from "./assets/icons/skills/pycharm.svg";

import aws from "./assets/icons/skills/aws.svg";
import linode from "./assets/icons/skills/linode.svg";
import docker from "./assets/icons/skills/docker.svg";


import { AiFillCode, AiOutlineCloudServer, AiOutlineApi } from 'react-icons/ai';
import { DiGoogleCloudPlatform, DiDatabase } from 'react-icons/di';
import { SiCodecademy, SiFlutter, SiJavascript, SiMongodb, SiReact, SiTailwindcss, SiHtml5, SiCss3, SiBootstrap, SiNodedotjs, SiExpress, SiLaravel, SiNginx, SiRabbitmq, SiSocketdotio, SiGraphql, SiApifox, SiFirebase, SiSolidity, SiPhp, SiPython, SiPostgresql, SiMysql, SiRedis, SiVisualstudiocode, SiAndroidstudio, SiXcode, SiPycharm, SiAmazonaws, SiDocker, SiApache} from 'react-icons/si';
// import {}

import { NavModel } from './model/nav.model';

import hero from "./assets/banner/hero.png";

export const hero3d = hero;

/*------------------------------------------
-----------ICONS DATA
------------------------------------------*/

export const icons = {
    github: <AiOutlineGithub />,
    instagram: <AiOutlineInstagram />,
    linkedin: <AiOutlineLinkedin />,
    download: <AiOutlineDownload />
}

/*------------------------------------------
-----------NAVIGATION DATA
------------------------------------------*/

export const nav = [
    new NavModel({ id: 1, name: "Home", path: "home" }),
    new NavModel({ id: 2, name: "Features", path: "features" }),
    new NavModel({ id: 3, name: "Portfolio", path: "portfolio" }),
    new NavModel({id: 4, name: "Skills", path: "skills"}),
    new NavModel({ id: 5, name: "Contact", path: "contact" })
]

/*------------------------------------------
-----------FEATURES DATA
------------------------------------------*/

export const features = [
    { title: "Mobile App Development", desc: "Development and deployment of cross platform application using flutter for android and iOS platform", icon: mobile },
    { title: "Frontend Development", desc: "Drive the architecture and implementation of intuitive and  user friendly websites, for seamless user experiences.", icon: frontend },
    { title: "Backend Development", desc: "Design and maintenance of scaleable, secure, and efficient backend systems, optimizing databases and APIs.", icon: backend },
    { title: "WEB 3.0", desc: "Development of decentralized applications (dApps) leveraging blockchain technology, implementing smart contract.", icon: web3 },
    { title: "Cloud Computing", desc: "Manage the infrastructure and deployment strategies on cloud platforms, ensuring reliability, scalability, and cost-efficiency", icon: cloud },
    { title: "SEO", desc: "Strategise and execute comprehensive SEO initiatives, conducting site audits, and optimizing content", icon: seo },
]

/*------------------------------------------
-----------PORTFOLIO DATA
------------------------------------------*/

export const portfolio = [
    {
        type: "mobile", projectclass: "Flutter Mobile", company: "Cloud Clinic", desc: "Allowing Patients have seamless virtual consultations with doctors on the platform", banner: ccl,
        available: [
            { platform: "Android", url: "https://play.google.com/store/apps/details?id=ng.cloudclinic.cloud_clinic_mobile" },
            { platform: "iOS", url: "https://apps.apple.com/ng/app/my-cloudclinic/id6444833387" }
        ]
    },
    {
        type: "mobile", projectclass: "Flutter Mobile", company: "Cloud Clinic Partners", desc: "Onboarding medical facilities and management of their sales within the platform", banner: cclpartners,
        available: [
            { platform: "Android", url: "https://play.google.com/store/apps/details?id=com.cloudclinic.ng.cloudclinic_partner_app" },
            { platform: "iOS", url: "https://apps.apple.com/ng/app/cloudclinic-partner/id1668991494" }
        ]
    },
    {
        type: "mobile", projectclass: "Flutter Mobile", company: "Fregies", desc: "A case study project for a groceries store with Firebase for authentication and storage ", banner: fregies,
        available: [
            { platform: "Android", url: "https://raw.githubusercontent.com/ralphbetta/assets/main/fregies.apk" }
        ]
    },
    {
        type: "mobile", projectclass: "Flutter Mobile", company: "Promeal", desc: "Allows seamless management of meal circulations from claiming to transferring of allocated potions.", banner: promeal,
        available: [
            { platform: "Android", url: "https://raw.githubusercontent.com/ralphbetta/assets/main/promeal.apk" },
            // { platform: "Android", url: "https://d.apkpure.com/b/APK/online.promeal.bubblemeet?versionCode=1" },
        ]
    },
    {
        type: "mobile", projectclass: "Flutter Mobile", company: "Smart Referal", desc: "Tracking referrals to Smartcryptobet and funding the referrer’s  wallet with their referral bonuses", banner: smartreferral,
        available: [
            { platform: "Android", url: "https://raw.githubusercontent.com/ralphbetta/assets/main/smart-referral.apk" },
        ]
    },

    // WEB STARTS HERE

    {
        type: "web", projectclass: "Nodejs | Reactjs", company: "Bridge Numeric", desc: "BnPortalNg is a department of environmental service inspection system for habitable property charges", banner: bnportalng,
        available: [
            { platform: "Client Side", url: "https://bnportalng.com" },
            { platform: "Server Side", url: "https://api.bnportalng.com/docs" },
        ]
    },

    {
        type: "web", projectclass: "Backend-Nodejs", company: "Praxium", desc: "“Hellotioo” An e-learning platform for students, an school management system with AI generated lesson templates.", banner: hellotio,
        available: [
            { platform: "Web", url: "https://hellotioo.com" },
        ]
    },
    {
        type: "web", projectclass: "Flutter Web | Nodejs", company: "SmartAdmin", desc: "Providing API as a service for virtual sport games integration and managing of crypto payment evaluation", banner: smartadmin,
        available: [
            { platform: "Web", url: "https://account.smartcryptobet.co" },
        ]
    },
    {
        type: "web", projectclass: "Backend-Nodejs", company: "ProMeal", desc: "Allows seamless management of meal circulations from claiming to transferring of allocated potions.", banner: promealweb,
        available: [
            { platform: "Web", url: "https://promeal.bubblemeet.online" }
        ]
    },
    {
        type: "web", projectclass: "Laravel | Bootsrap", company: "Smartcryptobet", desc: "Managing all virtual gaming activities, authorization, ads placement, admin user assignments and more.", banner: smartbet,
        available: [
            { platform: "Web", url: "https://webadmin.smartcryptobet.co" }
        ]
    },
]


export const skillsCategories = [
    { type: "frontend", name: "Frontend Stacks", icon: <AiFillCode/> },
    { type: "backend", name: "Backend Stacks", icon: <AiOutlineApi/> },
    { type: "language", name: "Programming Languages",  icon: <SiCodecademy/> },
    { type: "database", name: "Databases",  icon: <DiDatabase/> },
    { type: "tool", name: "Tools/Environment", icon: <AiFillTool/> },
    { type: "cloud", name: "Cloud", icon: <AiOutlineCloudServer/> },
];

export const skills = [
    { type: "backend", name: "Nodejs", icon: <SiNodedotjs/> , img: node,  },
    { type: "backend", name: "Express",  icon: <SiExpress/> , img: express },
    { type: "backend", name: "Laravel", icon: <SiLaravel/> , img: laravel },
    { type: "backend", name: "Apache", icon: <SiApache/> , img: apache },
    { type: "backend", name: "Nginx", icon: <SiNginx/> , img: nginix },
    { type: "backend", name: "RabbitMQ", icon: <SiRabbitmq/> , img: rabbitMQ },
    { type: "backend", name: "Socket.io", icon: <SiSocketdotio/> , img: socket },
    { type: "backend", name: "GraphQL", icon: <SiGraphql/> , img: graphql },
    { type: "backend", name: "Restful API", icon: <SiApifox/> , img: api },
    { type: "backend", name: "Firebase", icon: <SiFirebase/> , img: firebase },
    { type: "backend", name: "Solidity", icon: <SiSolidity/> , img: solidity },

    { type: "frontend", name: "Flutter", icon: <SiFlutter/> , img: flutter },
    { type: "frontend", name: "Reactjs", icon: <SiReact/> , img: react },
    { type: "frontend", name: "Tailwind", icon: <SiTailwindcss/> , img: tailwind },
    { type: "frontend", name: "HTML", icon: <SiHtml5/> , img: html },
    { type: "frontend", name: "CSS", icon: <SiCss3/> , img: css },
    { type: "frontend", name: "Bootsrap", icon: <SiBootstrap/> , img: bootstrap },

    { type: "language", name: "PHP", icon: <SiPhp/> , img: php },
    { type: "language", name: "Javascipt", icon: <SiJavascript/> , img: javascript },
    { type: "language", name: "Python", icon: <SiPython/> , img: python },

    { type: "database", name: "Postgress", icon: <SiPostgresql/> , img: postgress },
    { type: "database", name: "MySQL", icon: <SiMysql/> , img: mysql },
    { type: "database", name: "MongoDB", icon: <SiMongodb/> , img: mongodb },
    { type: "database", name: "Firebase Storage", icon: <SiFirebase/> , img: firebasestorage },
    { type: "database", name: "Radis", icon: <SiRedis/> , img: redis },

    { type: "tool", name: "VS Code", icon: <SiVisualstudiocode/> , img: vscode },
    { type: "tool", name: "Android Studio", icon: <SiAndroidstudio/> , img: androidstudio },
    { type: "tool", name: "XCode", icon: <SiXcode/> , img: xcode },
    { type: "tool", name: "Pycharm", icon: <SiPycharm/> , img: pycharm },

    { type: "cloud", name: "AWS", icon: <SiAmazonaws/> , img: aws },
    { type: "cloud", name: "Google Cloud", icon: <DiGoogleCloudPlatform/> , img: linode },
    { type: "cloud", name: "Docker", icon: <SiDocker/> , img: docker },
];

export const profile = {
    name: "Raphael Etta",
    role: "Software Developer",
    caption: "I am available for freelance, part-time and full-time roles. Connect with me via or extend a call.",
    phone: "+2348133726269",
    email: "ralphbetta@gmail.com",//"contact@ralphbetta.com",
    linkedin: "https://www.linkedin.com/in/raphael-etta-5410a4194/",
    github: "https://github.com/ralphbetta",
    instagram: "https://www.instagram.com/ralphbetta",
    remote: "https://raw.githubusercontent.com/ralphbetta/assets/main/",
    year: "2024",
    banner: contact

}