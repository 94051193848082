import { icons, hero3d, profile } from '../data';
import { motion } from 'framer-motion';
import { slideIn, fadeIn, zoomIn } from '../motion';

const Hero = () => {
    return (
        <div className='sides flex flex-col md:flex-row justify-between items-center py-7 mt-10 md:mt-20' id='home'>
            <div
                className='md:w-1/2 flex flex-col space-y-6 justify-center'>
                <p className=''> WELCOME TO MY SPACE</p>

                <motion.div
                    initial={slideIn('right', 0.01).initial}
                    whileInView={slideIn('right', 0.01).animate}
                >
                    <h2 className='text-[35px] md:text-[45px] font-semibold'>Hi, I’m <span className='text-primary'>Raphael Etta</span></h2>
                    <h2 className='text-[25px] md:text-[32px] mt-2 font-semibold uppercase'> <span className='text-primary'>*</span>Software Developer<span className='text-primary'>*</span></h2>
                </motion.div>

                <motion.p
                    initial={slideIn('right', 5).initial}
                    whileInView={slideIn('right', 5).animate}
                    className='hint'>Experienced website and hybrid mobile app developer, specialized in developing and deploying professional and user-friendly applications for complex and scalable intents, with hands-on experience in industry-relevant tools, languages, and frameworks.</motion.p>


                <div className='flex flex-row justify-between uppercase'>
                    <div>
                        <h3>Find Me On</h3>

                        <div className='flex flex-row space-x-5'>
                            <motion.a
                                href={profile.linkedin} target="_blank" rel="noopener noreferrer"
                                initial={zoomIn(0.05, "max").initial}
                                whileInView={zoomIn(0.05, "max").animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 social'> {icons.linkedin}</motion.a>

                            <motion.a
                                href={profile.github} target="_blank" rel="noopener noreferrer"
                                initial={zoomIn(1, "max").initial}
                                whileInView={zoomIn(5, "max").animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 social'> {icons.github}</motion.a>

                            <motion.a
                                href={profile.instagram} target="_blank" rel="noopener noreferrer"
                                
                                initial={zoomIn(10, "max").initial}
                                whileInView={zoomIn(10, "max").animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 social'> {icons.instagram}</motion.a>
                        </div>


                    </div>
                    <div>
                        <h3>For Detailed Info</h3>
                        <motion.a
                            initial={fadeIn('down', null).initial}
                            whileInView={fadeIn('down', null).animate}

                            href={`${profile.remote}ralphbetta-resume.pdf`}

                            className='flex justify-center items-center py-[10px] md:py-[11px] space-x-2 elevate mt-4'>
                            {icons.download} <span className='text-primary'>Download CV</span>
                        </motion.a>
                    </div>
                </div>
            </div>

            <motion.div

                initial={fadeIn('up', null).initial}
                whileInView={fadeIn('up', 5).animate}

                className=' md:w-[45%] mt-5 md:mt-0'>
                <img src={hero3d} alt="" />
            </motion.div>

        </div>
    )
}

export default Hero