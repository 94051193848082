import React, { useState, useEffect } from 'react'
import { skills, skillsCategories } from '../data'
import { motion } from 'framer-motion';
import { zoomIn, slideIn } from '../motion';



const Skills = () => {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [filtered, setFiltered] = useState<any>([]);

    useEffect(() => {
        const data = skills.filter((element) => element.type === skillsCategories[0].type);
        setFiltered(data);
    }, [])


    interface props {
        index: number,
        name: string
    };


    const toggleTab = ({ index, name }: props) => {

        setActiveIndex(index);
        const data = skills.filter((element) => element.type === name);
        setFiltered(data);

    }


    return (
        <div className='sides mt-16' id='skills'>
            <h5 className='mb-3 font-semibold text-primary'>SKILLS</h5>
            <h2 className='text-4xl font-semibold'>Technical Skills</h2>


            <div className='flex flex-row justify-start items-start space-x-8 mt-8'>

                <motion.div

                    initial={slideIn('right', 2).initial}
                    whileInView={slideIn('right', 2).animate}

                    className=' flex flex-col p-0 elevate md:w-[40%]'>
                    {skillsCategories.map((item, index) =>
                        <button onClick={() => toggleTab({ index, name: item.type })} className={`flex flex-col md:flex-row rounded-md py-3 px-4 items-center  ${index !== activeIndex ? "bg-background" : "bg-primary text-white rounded-md"}`}
                            key={index}>
                            <span className={`text-3xl ${index !== activeIndex ? "hint" : ""}`}>{item.icon}</span>
                            <div className={` ${index !== activeIndex ? "hint" : ""} block md:hidden`}> {item.type}</div>
                            <span className={`ml-2 hidden md:inline-flex ${index !== activeIndex ? "hint": ""}`}> {item.name}</span>
                        </button> 
                    )}
                </motion.div>

                <div className='grid grid-cols-2 md:grid-cols-4 w-full gap-4'>
                    {
                        filtered.map((item: { type: string; icon: string; name: string }, index: number) =>

                            <motion.div
                                initial={zoomIn(index, 'min').initial}
                                whileInView={zoomIn(index, 'min').animate}
                                className=' elevate flex flex-col justify-center space-y-1 md:space-y-3 md:py-8 items-center' key={index}>
                                {/* <img className=' w-10 h-10 md:w-14 md:h-14 text-white' src={item.icon} alt="" /> */}
                                <span className=' text-4xl'>{item.icon}</span>
                                <p className='text-primary'>{item.name}</p>
                            </motion.div>
                        )}
                </div>

            </div>

        </div>
    )
}

export default Skills