import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container px-[5%] p-6">
      <h1 className="text-3xl font-bold mb-6 mt-16">Privacy Policy for Ralphbetta</h1>
      <p className="text-gray-700 mb-4">
        <strong>Effective Date:</strong> 9th October, 2024 <br />
        <strong>Last Updated:</strong> 9th October, 2024
      </p>

      <p className="text-gray-700 mb-4">
        At Ralphbetta, we value your privacy and are committed to protecting your
        personal information. This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your information when you use our customer
        responder platform, including interactions with Meta channels such as
        WhatsApp and Facebook. By using Ralphbetta, you agree to the terms
        outlined in this Privacy Policy. If you do not agree with the terms,
        please discontinue using our services.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
      <p className="text-gray-700 mb-4">
        We collect various types of information to deliver and improve our
        services, including:
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">a. Personal Data</h3>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Profile picture (if applicable)</li>
        <li>
          Login credentials (such as usernames, passwords, or social login data
          from services like Google or Facebook)
        </li>
      </ul>
      <p className="text-gray-700 mb-4">
        This information is required to authenticate your identity and provide
        you with secure access to our platform.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">b. Meta Channel Data</h3>
      <p className="text-gray-700 mb-4">
        When you connect Ralphbetta with Meta channels (e.g., WhatsApp, Facebook),
        we collect data related to your interactions on these platforms, such as:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Messages sent or received through the platform</li>
        <li>User IDs associated with your Meta accounts</li>
        <li>Other metadata (e.g., timestamps, chat statuses)</li>
      </ul>

      <h3 className="text-xl font-semibold mt-4 mb-2">c. Usage Data</h3>
      <p className="text-gray-700 mb-4">
        We automatically collect certain data about how you interact with
        Ralphbetta. This may include:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>IP addresses</li>
        <li>Browser type and version</li>
        <li>Device information (e.g., device ID, operating system)</li>
        <li>Pages or features accessed</li>
        <li>Time and date of access</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        We use the collected data for various purposes, including but not limited to:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>
          <strong>Account Management:</strong> To create and manage your account, provide login functionality, and authenticate users.
        </li>
        <li>
          <strong>Service Delivery:</strong> To enable communication between you and your customers via Meta channels like WhatsApp and Facebook.
        </li>
        <li>
          <strong>Platform Improvement:</strong> To analyze usage patterns, monitor platform performance, and enhance features.
        </li>
        <li>
          <strong>Notifications:</strong> To inform you of updates, offers, or changes to our services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> To comply with legal obligations, prevent fraudulent activities, and respond to requests from law enforcement authorities if required.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Data Sharing and Disclosure</h2>
      <p className="text-gray-700 mb-4">
        Ralphbetta does not sell or rent your personal information to third
        parties. However, we may share your information in the following
        circumstances:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>
          <strong>Service Providers:</strong> We may share data with trusted
          third-party service providers who assist us with operations such as
          data hosting, payment processing, or customer support.
        </li>
        <li>
          <strong>Meta Platforms:</strong> When you use Meta channels (e.g.,
          WhatsApp, Facebook), your data is subject to their privacy policies.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information
          when required by law, in response to a court order, or to protect the
          rights and safety of our users and the public.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>
      <p className="text-gray-700 mb-4">
        We take your privacy seriously and have implemented industry-standard
        security measures to protect your data from unauthorized access,
        disclosure, alteration, or destruction. These measures include:
      </p>

      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Encryption of sensitive information during transmission (e.g., login credentials)</li>
        <li>Regular security audits and vulnerability assessments</li>
        <li>Restricted access to personal data to authorized personnel only</li>
      </ul>

      <p className="text-gray-700 mb-4">
        While we strive to use commercially acceptable means to protect your
        information, no method of transmission over the internet or electronic
        storage is 100% secure. We cannot guarantee the absolute security of
        your personal data.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Login Dialog and Authentication</h2>
      <p className="text-gray-700 mb-4">
        When you log into Ralphbetta, whether via traditional login or through
        third-party authentication services like Google or Facebook, we collect
        and store the necessary credentials and tokens to provide you with
        secure access. This data is used strictly for authentication purposes
        and is protected with encryption and secure protocols.
      </p>
      <p className="text-gray-700 mb-4">
        <strong>Social Login:</strong> If you choose to log in using third-party
        services (e.g., Facebook Login), we may receive limited profile
        information such as your email address and public profile. This data
        will only be used to authenticate you and enhance your experience on
        Ralphbetta.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">6. App Details and Permissions</h2>
      <p className="text-gray-700 mb-4">
        When using Ralphbetta, we may request certain permissions or collect
        specific app-related details to improve the service:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>
          <strong>App-Specific Data:</strong> This includes preferences you set
          within the app, such as notification settings or customization
          options.
        </li>
        <li>
          <strong>Permissions:</strong> We may request access to certain device
          features (e.g., contacts, camera, or location) to offer specific
          functionalities within the platform. You will have the option to grant
          or deny these permissions, and they will only be used for their
          intended purpose.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">7. Your Rights</h2>
      <p className="text-gray-700 mb-4">
        You have the following rights with respect to your personal information:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
        <li><strong>Correction:</strong> You can update or correct any inaccuracies in your data.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your data, subject to any legal obligations we may have to retain it.</li>
        <li><strong>Data Portability:</strong> You may request a copy of your personal data in a machine-readable format.</li>
      </ul>

      <p className="text-gray-700 mb-4">
        To exercise these rights, please contact us at contact@ralphbetta.com
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">8. Cookies and Tracking Technologies</h2>
      <p className="text-gray-700 mb-4">
        We may use cookies and similar tracking technologies to track user
        activity on our platform and hold certain information. Cookies are small
        data files stored on your device. You can instruct your browser to
        refuse all cookies or to indicate when a cookie is being sent, but doing
        so may affect your ability to use certain features of Ralphbetta.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">9. Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and the date of the latest revision will be
        indicated at the top. By continuing to use our services after changes
        are made, you agree to the updated Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
