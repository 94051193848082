import React, { useState } from 'react'
import { profile, icons } from '../data'
import { motion } from 'framer-motion';
import { fadeStagger, zoomIn, } from '../motion';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import emailjs from 'emailjs-com';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });


    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        console.log('Form submitted:', formData);

        const isFormEmpty = Object.values(formData).some(value => value.trim() === '');

        if (isFormEmpty) {
            toast.error("Please fill in all fields", { position: toast.POSITION.TOP_RIGHT });
        } else {

            console.log('Form submitted:', formData);

            var templateParams = {
                subject: formData.subject,
                from_name: formData.name,
                from_email: formData.email,
                phone: formData.phone,
                message: formData.message,
                to_name: 'Ralphbetta'
            };

            // email service created with: https://www.zoho.com/
            //smtp service created with: https://www.emailjs.com/

            emailjs.send('service_74jt6pk', 'template_tfbxm1s', templateParams, '_Vs-66k-Y3c3tKv1x')
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                    toast.success("Email Sent successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }, function (error) {
                    toast.error("Oops! Could not sent email", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    console.log('FAILED...', error);
                });

        }

    };

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    return (
        <div onSubmit={handleSubmit} className='sides mt-12' id='contact'>
            <h5 className='mb-3 font-semibold text-primary'>Contact</h5>
            <h2 className='text-4xl font-semibold'>Connect With Me</h2>

            <div className='flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 my-7'>
                <motion.div

                    initial={fadeStagger.initial}
                    whileInView={fadeStagger.animate}

                    className=' w-full md:w-[40%] elevate flex flex-col space-y-3'>
                    <img className=' rounded-md' src={profile.banner} alt="" />
                    <div>
                        <h2 className=' text-2xl font-semibold'>{profile.name}</h2>
                        <p className='hint text-sm'>{profile.role}</p>
                    </div>
                    <p className='hint'>{profile.caption}</p>
                    <div className='hint'>
                        <p><span className='text-primary'>Phone:</span> {profile.phone}</p>
                        <p><span className='text-primary'>Email</span> {profile.email}</p>
                    </div>
                    <div>
                        <h3 className='hint'>Find Me On</h3>
                        <div className='flex flex-row space-x-5'>
                            <motion.a
                                href={profile.linkedin} target="_blank" rel="noopener noreferrer"
                                initial={zoomIn(0.05, "max").initial}
                                whileInView={zoomIn(0.05, "").animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 h-10 w-10'> {icons.linkedin}</motion.a>
                            <motion.a
                                href={profile.github} target="_blank" rel="noopener noreferrer"
                                initial={zoomIn(null, "max").initial}
                                whileInView={zoomIn(5, 'max').animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 h-10 w-10'> {icons.github}</motion.a>
                            <motion.a
                                href={profile.instagram} target="_blank" rel="noopener noreferrer"
                                initial={zoomIn(null, "max").initial}
                                whileInView={zoomIn(10, "max").animate}
                                className='flex justify-center items-center space-x-2 elevate mt-4 h-10 w-10'> {icons.instagram}</motion.a>
                        </div>
                    </div>
                </motion.div>

                <form className='  md:w-[60%] elevate flex flex-col space-y-3 md:space-y-0 justify-between'>
                    <div className='flex flex-row space-x-4'>
                        <div className=' w-full'>
                            <p>Your Name</p>
                            <input
                                value={formData.name} onChange={handleChange} className='mt-2 w-full py-3 focus:outline-none rounded-md px-2 intrude' placeholder='John Doe' type='text' name="name" autoComplete='name' />
                        </div>
                        <div className=' w-full'>
                            <p>Phone Number</p>
                            <input
                                value={formData.phone} onChange={handleChange} className='mt-2 w-full py-3 focus:outline-none rounded-md px-2 intrude' placeholder='+234(0)8XX XXX XXXX' type='phone' name="phone" autoComplete='phone' />
                        </div>
                    </div>

                    <div className=' w-full'>
                        <p>Email</p>
                        <input
                            value={formData.email} onChange={handleChange} className='mt-2 w-full py-3 focus:outline-none rounded-md px-2 intrude' placeholder='johndoe@mail.com' type='email' name="email" autoComplete='email' />
                    </div>

                    <div className=' w-full'>
                        <p>Subject</p>
                        <input
                            value={formData.subject} onChange={handleChange} className='mt-2 w-full py-3 focus:outline-none rounded-md px-2 intrude' placeholder='message title' type='text' name="subject" autoComplete='subject' />
                    </div>

                    <div className=' w-full'>
                        <p>Your Message</p>
                        <textarea
                            value={formData.message} onChange={handleChange} placeholder='Message here' rows={3} className='mt-2  w-full intrude' autoComplete='message' name='message'>

                        </textarea>
                    </div>
                    <motion.button
                        initial={zoomIn(0.05, 'min').initial}
                        whileInView={zoomIn(0.05, 'min').animate}
                        type='submit'
                        className='elevate py-3 bg-primary text-white'>
                        Send Message
                    </motion.button>
                </form>
            </div>

            <ToastContainer />
        </div>
    )
}

export default Contact