export class NavModel {
    id: number | undefined;
    name: string = '';
    path: string = '';

    constructor(initializer?: any){
        if(!initializer) return;
        if(initializer.id) this.id = initializer.id;
        if(initializer.name) this.name = initializer.name;
        if(initializer.path) this.path = initializer.path;
    }

}