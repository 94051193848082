import { useState } from 'react'
import { nav } from '../data';
import { Link } from 'react-scroll';
import logo from '../assets/iconlight.png'; //add declaration file and update tsconfig for this to work

import { BsFillBrightnessHighFill, BsBrightnessLowFill } from "react-icons/bs";

import {
    AiOutlineMenu,
    AiOutlineClose,
} from 'react-icons/ai';

const Nav = () => {

    const [dark, setDark] = useState<boolean>(false);

    const toggleTheme = () => {
        document.documentElement.classList.toggle('dark');
        setDark(!dark);
    }

    const [activeIndex, setActiveIndex] = useState<number>(0);
    // const [activeTab, setActiveTab] = useState<number | string>(0);
    const [expand, setExpand] = useState<boolean>(false);

    interface props {
        index: number
    };

    const toggleActiveIndex = ({ index }: props) => {
        console.log(index);
        setActiveIndex(index);
    }

    const expandMenu = () => {
        setExpand(!expand);
        console.log(expand);
    }

    const toggleTab = (id: number) => {
        toggleActiveIndex({ index: id })
    }


    return (
        <nav className='fixed top-0 right-0 left-0 z-50 '>
            <div className='sides flex flex-row justify-between items-center py-5 h-16 md:h-20 themebg'>
                <div className=' flex flex-row justify-center items-center space-x-2'>
                    <img className='w-7 h-7' src={logo} alt="" />
                    <h1 className=' font-bold text-[20px] hidden md:block'>RALPH<span className=' text-primary'>BETTA.</span></h1>
                </div>
                <div className='hidden md:flex flex-row items-center space-x-5 cursor-pointer'>
                    {nav.map((item, id) =>
                        <div key={id} className={id === activeIndex ? "elevate py-1 text-primary underline-offset-4" : ""}>
                            <Link

                                onClick={() => toggleTab(id)}
                                to={item.path}
                                spy={true}
                                smooth={true}
                                offset={-100}

                            > {item.name}</Link>
                        </div>
                    )}
                    <button onClick={toggleTheme} className=' elevate py-2'>
                        {dark ? <BsFillBrightnessHighFill /> : <BsBrightnessLowFill />}
                    </button>
                </div>

                <div className=' flex justify-center items-center space-x-5 md:hidden'>
                    <button onClick={toggleTheme} className=' elevate py-2 md:hidden'>
                        {dark ? <BsFillBrightnessHighFill /> : <BsBrightnessLowFill/>}
                    </button>

                    <button onClick={expandMenu} className=' elevate py-2 md:hidden'>
                        {expand ? <AiOutlineClose /> : <AiOutlineMenu />}
                    </button>

                </div>
            </div>

            {/* Mobile Menu */}

            <div className={expand ? 'flex flex-col cursor-pointer bg-primary px-5 py-5 space-y-5' : 'hidden transition-all duration-300'}>
                {nav.map((item, id) =>
                    <div key={id} className={id === activeIndex ? "underline text-white underline-offset-4 transition-all duration-300 " : ""}>
                        <Link

                            onClick={() => toggleActiveIndex({ index: id })}
                            to={item.path}
                            spy={true}
                            smooth={true}
                            offset={-100}

                        > {item.name}</Link>
                    </div>
                )}
            </div>

        </nav>
    )
}

export default Nav