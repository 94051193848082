// import React from 'react';
// import Nav from './components/Nav';
// import Hero from './components/Hero';
// import Features from './components/Features';
// import MyPortfolio from './components/Portfolio';
// import Skills from './components/Skills';
// import Contact from './components/Contact';
// import Footer from './components/Footer';
// import FloatingButton from './components/FloatingButton';

// function App() {
//   return (
//     <div>
//      <Nav/>
//      <Hero/>
//      <Features/>
//      <MyPortfolio/>
//      <Skills/>
//      <FloatingButton/>
//      <Contact/>
//      <Footer/>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav';
import Hero from './components/Hero';
import Features from './components/Features';
import MyPortfolio from './components/Portfolio';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FloatingButton from './components/FloatingButton';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

function App() {
  return (
    <Router>
      <div>
        <Nav />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Features />
              <MyPortfolio />
              <Skills />
              <FloatingButton />
              <Contact />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
