import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container px-[5%] mt-16 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Use for Ralphbetta</h1>

      <p className="text-gray-700 mb-4">
      <strong>Effective Date:</strong> 9th October, 2024 <br />
      <strong>Last Updated:</strong> 9th October, 2024
      </p>

      <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="text-gray-700 mb-4">
        By accessing or using Ralphbetta, you agree to comply with these Terms of Use. If you do not agree with any part of these terms, you must not use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. Account Registration</h2>
      <p className="text-gray-700 mb-4">
        To access certain features of Ralphbetta, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
      </p>
      <p className="text-gray-700 mb-4">
        You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. Login Dialog and Authentication</h2>
      <p className="text-gray-700 mb-4">
        When you log into Ralphbetta, whether through traditional login or third-party services like Google or Facebook, you agree to the collection and storage of necessary authentication credentials and tokens. This data is used solely for providing secure access to your account.
      </p>
      <p className="text-gray-700 mb-4">
        If you choose to log in via third-party services, you may grant us access to certain information, such as your email address and public profile. We will use this information only for authentication and to enhance your user experience.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. App Details and Permissions</h2>
      <p className="text-gray-700 mb-4">
        Ralphbetta may request permissions to access certain features on your device, such as contacts or location, to provide enhanced services. You can choose to grant or deny these permissions, but please note that denying permissions may limit your ability to use certain features.
      </p>
      <p className="text-gray-700 mb-4">
        Any app-specific data collected will be used in accordance with our Privacy Policy and solely for the purpose of improving our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. User Responsibilities</h2>
      <p className="text-gray-700 mb-4">
        You agree to use Ralphbetta only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit anyone else's use of the service.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Termination</h2>
      <p className="text-gray-700 mb-4">
        We reserve the right to terminate or suspend your account at our discretion, without notice, for conduct that violates these Terms of Use or is harmful to other users of Ralphbetta, our business interests, or the public.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
      <p className="text-gray-700 mb-4">
        To the maximum extent permitted by law, Ralphbetta shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of or inability to use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">8. Changes to Terms of Use</h2>
      <p className="text-gray-700 mb-4">
        We may modify these Terms of Use from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top. Your continued use of Ralphbetta after changes are made constitutes your acceptance of the new Terms of Use.
      </p>

      <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions or concerns about these Terms of Use, please contact us at:
      </p>
      <p className="text-gray-700 mb-4">
        Email: contact@ralphbetta.com <br />
        Address: 105 Nwaniba Road, Uyo, Akwa Ibom State <br />
      </p>

      <p className="text-gray-700 mb-4">Thank you for using Ralphbetta!</p>
    </div>
  );
};

export default TermsOfUse;
