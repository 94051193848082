import React, { useState, useEffect } from 'react'
import { portfolio } from '../data'
import { AiFillEye, AiOutlineDownload } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { zoomIn, slideIn } from '../motion';

const MyPortfolio = () => {

    const tab = ['Mobile Applications', "Websites"];

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [filtered, setFiltered] = useState<any>([]);

    useEffect(() => {
        const data = portfolio.filter((element) => element.type === 'mobile');
        setFiltered(data);
    }, [])

    interface props {
        index: number
    };

    interface portfolioProps {
        banner: string; company: string; desc: string; projectclass: string; type: string; available: { platform: string; url: string }[]
    }

    const toggleTab = ({ index }: props) => {

        setActiveIndex(index);
        const selection = index === 0 ? "mobile" : "web";
        const data = portfolio.filter((element) => element.type === selection);
        setFiltered(data);

    }


    return (
        <div className='sides mt-16' id='portfolio'>
            <h5 className='mb-3 font-semibold text-primary'>PORTFOLIO</h5>
            <h2 className='text-4xl font-semibold'>My Latest Work</h2>


            <motion.div
                initial={zoomIn(1, 'min').initial}
                whileInView={zoomIn(1, 'min').animate}

                className=' flex flex-row text-center justify-center my-16'>
                {tab.map((item, index) =>

                    <button onClick={() => toggleTab({ index })} className={` w-[50%] md:w-[25%] md:px-3 md:py-6 flex justify-center items-center  ${index !== activeIndex ? "elevate" : "intrude"} ${index !== 0 ? "rounded-l-none" : "rounded-r-none"}`}
                        key={index}>
                        {item}
                    </button>
                )}
            </motion.div>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 mt-8'>
                {
                    filtered.map((item: portfolioProps, index: number) =>

                        <motion.div

                          
                        initial={slideIn('right', index*2).initial}
                        whileInView={slideIn('right', index*2).animate}

                            className=' elevate flex flex-col space-y-4 px-5 py-5 justify-between' key={index}>
                            <img src={item.banner} alt="" className='w-full h-[155px] rounded-md' />
                            <div className='flex justify-between items-center'>
                            <h3 className='text-lg font-semibold'>{item.company}</h3>
                            <span className='text-primary'>{item.projectclass}</span>
                            </div>
                            <p className='hint'>{item.desc}</p>

                            <div className='flex flex-row space-x-2 justify-end'>
                                {item.available?.map((data, index) =>
                                    <a key={index} href={data.url} className=' border-2 border-primary inline-flex justify-center items-center space-x-2 p-1 rounded-md'>
                                        {data.platform === 'Android' || data.platform === 'iOS' ? <AiOutlineDownload className=' text-primary font-bold' /> : <AiFillEye className=' text-primary' />}
                                        <span>{data.platform}</span>
                                    </a>
                                )}
                            </div>

                        </motion.div>
                    )}
            </div>
        </div>
    )
}

export default MyPortfolio