import React, { useEffect, useState } from 'react'

import { animateScroll as scroll } from 'react-scroll'
import { AiOutlineArrowUp } from 'react-icons/ai'


const FloatingButton = () => {


    const [show, setShow] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {

            return window.pageYOffset > 600 ? setShow(true) : setShow(false);



        });
    });

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <div>

            {
                show ?
                    <button onClick={() => scrollToTop()}
                        className=' text-white rounded-md bg-primary w-12 h-12 hover:bg-secondary fixed right-5 md:right-10 bottom-12 cursor-pointer flex justify-center items-center transition-all'>
                        <AiOutlineArrowUp className='w-6 h-6' />
                    </button>
                    : <div></div>
            }


        </div>
    )
}

export default FloatingButton